<template>
    <mercur-card class="full-height-layout fill mx-4 mb-3">
        <h3 class="font-weight-normal">
            Column mapping for <span v-if="supplierProductImportedDetails">{{supplierProductImportedDetails.productName}}</span>
        </h3>
        <div v-if="supplierProductImportStatus === 'PROCESSING_FILE'" data-test="supplierImportMappingProcessing">
            File is being processed. Once ready the details will show here.
            <div class="mt-10">
                <mercur-spinner></mercur-spinner>
            </div>
        </div>
        <div v-else-if="supplierProductImportedDetails && !supplierProductImportedDetails.fileName" class="align-center">
            <p>
                Please upload a file first.
            </p>
            <mercur-button class="btn btn-blue" :to="uploadRoute">Upload now</mercur-button>
        </div>
        <p v-else-if="supplierProductImportedDetails">
            Please select for each column in the csv file if this should be mapped to one or more attributes, one or more data fields or if it should be ignored.
            <mercur-button v-if="recommendedMapping" class="btn btn-recommend btn-yellow" @click="triggerRecommendedDialog">Use recommended mapping</mercur-button>
            <mercur-button class="btn btn-yellow btn-undo" v-if="undoValues" @click="undoMapping">Undo</mercur-button>
        </p>

        <div class="row" v-if="supplierProductImportedDetails && supplierProductImportedDetails.fileName && supplierProductImportStatus !== 'PROCESSING_FILE'">
            <div class="col col-4"></div>
            <div class="col col-4 align-center"><strong>Attributes</strong></div>
            <div class="col col-4 align-center"><strong>Data</strong></div>
        </div>
        <div class="row fill pt-5" v-if="supplierProductImportedDetails && supplierProductImportedDetails.columnNames">
            <div class="col col-4 column-selection height-100">
                <mercur-menu class="add-button">
                    <mercur-button
                        :disabled="columnSelection.length === 0"
                        class="btn btn-icon btn-yellow"
                        data-test="supplierImportColumnAddMapping"
                    >
                        <i class="fas fa-plus"></i>
                    </mercur-button>
                    <template slot="dropdown">
                        <i class="pl-3">Add column{{columnSelection.length === 1 ? '' : 's'}} as:</i>
                        <hr>
                        <mercur-item data-test="supplierImportColumnAddMappingAttribute" @click.native="addColumnSelectionAs('ATTRIBUTE')">Attribute</mercur-item>
                        <mercur-item data-test="supplierImportColumnAddMappingData" @click.native="addColumnSelectionAs('DATA')">Data</mercur-item>
                    </template>
                </mercur-menu>
                <field-group title="Columns" class="field-group--scroll ml-10 height-100">
                    <p class="green">Mouseover a column to see sample data.</p>
                    <div v-for="columnName in supplierProductImportedDetails.columnNames" :key="columnName">
                        <mercur-checkbox
                            class="mb-0 mt-5 column-name"
                            v-model="columnSelection"
                            :value="columnName"
                            :title="`Example: ${supplierProductImportedDataSample ? supplierProductImportedDataSample[columnName.replace(' ', '_')] : ''}`"
                            :data-test="`supplierImportColumn_${columnName}`"
                        >
                            {{columnName}}<small>{{getNumberOfMappingsForColumn(columnName)}}</small>
                        </mercur-checkbox>
                    </div>
                </field-group>
            </div>
            <div class="col col-4 fill fill--force-scroll height-100">
                <div v-for="(mappedColumnSet, key) in $v.payload.columnMapping.$each.$iter" :key="key">
                <template v-if="mappedColumnSet.$model.type === 'ATTRIBUTE'">
                        <field-group
                            class="mb-10 field-group--small-title"
                            :class="{'field-group--recommended': mappedColumnSet.$model.recommended === true }"
                            :error="isInvalidField(mappedColumnSet.$model) && 'Invalid, column is not on the list of columns!'"
                            :title="mappedColumnSet.$model.columnNames.join(', ')"
                            :remove="() => columnMapping.splice(key, 1)"
                            :data-test="`supplierImportProductMappedColumnAttribute_${key}`"
                        >
                            <pretty-select
                                v-if="sortedAttributeNames"
                                :options="sortedAttributeNames"
                                placeholder="Mapped to"
                                :multiple="true"
                                @input="setColumnSetMappedToValue(mappedColumnSet.$model, $event)"
                                :value="getColumnSetMappedToValue(mappedColumnSet.$model)"
                                :ref="`attributeSelector_${key}`"
                                class="vs--full-width-options vs--font-size-small"
                                data-test="supplierImportProductColumnMappedSet"
                            >
                                <template slot="option" slot-scope="slotData" >
                                    <span :class="{'blue font-weight-bold': isTopArray(slotData), 'u-with-opacity': wasAlreadySelected(slotData)}">{{ Object.values(slotData)[0] }}</span>
                                </template>
                            </pretty-select>
                            <div v-else>
                                <mercur-spinner></mercur-spinner>
                            </div>
                            <span v-if="!mappedColumnSet.mappedTo.required" class="error">Please select one or more attributes</span>
                        </field-group>
                    </template>
                </div>
            </div>
            <div class="col col-4 fill fill--force-scroll height-100">
                <div class="ml-5 mr-10" v-for="(mappedColumnSet, key) in $v.payload.columnMapping.$each.$iter" :key="key">
                    <template v-if="mappedColumnSet.$model.type === 'DATA'">
                        <field-group
                            class=" mb-10 field-group--small-title"
                            :class="{'field-group--recommended': mappedColumnSet.$model.recommended === true }"
                            :error="isInvalidField(mappedColumnSet.$model) && 'Invalid, column is not on the list of columns!'" :title="mappedColumnSet.$model.columnNames.join(', ')"
                            :remove="() => columnMapping.splice(key, 1)"
                            :data-test="`supplierImportProductMappedColumnData_${key}`"
                        >
                            <pretty-select
                                v-if="mercurDataColumns"
                                placeholder="Mapped to"
                                class="vs--full-width-options vs--font-size-small"
                                :options="mercurDataColumns"
                                @input="setColumnSetMappedToValue(mappedColumnSet.$model, $event)"
                                :value="getColumnSetMappedToValue(mappedColumnSet.$model)"
                                :ref="`dataSelector_${key}`"
                                :multiple="true"
                                data-test="supplierImportProductColumnMappedSet"
                            ></pretty-select>
                            <span v-if="!mappedColumnSet.mappedTo.required" class="error">Please select one or more data properties</span>
                        </field-group>
                    </template>

                </div>
            </div>
        </div>
        <div class="row mt-5" v-if="supplierProductImportedDetails && supplierProductImportedDetails.fileName">
            <div class="col col-9 error pl-10">
                <p v-if="!$v.payload.columnMapping.hasRequiredDataMapped">You still need to map the following data properties: {{ missingData.join(', ') }}</p>
                <p v-if="!$v.payload.columnMapping.minimumAttributes">You need to map to at least 3 attributes</p>
                <p v-if="!$v.payload.columnMapping.isColumnMappingValid">There are some invalid mappings, please remove or fix.</p>
                <p v-if="!$v.payload.columnMapping.usedAllAttributes">All required attributes need to be used! ({{ missingAttributes.join(', ') }})</p>
                <p v-if="!$v.payload.columnMapping.allUniqueMercurAttributes">You have one or more attributes mapped twice. Please check these attributes: {{ duplicateAttributes.join(', ') }}</p>
                <p v-if="!$v.payload.columnMapping.allUniqueAttributeColumns">You have one or more attribute columns mapped twice. Please check these attribute columns: {{ duplicateAttributeColumns.join(', ') }}</p>
                <p v-if="!$v.payload.columnMapping.allUniqueDataColumns">You have one or more data columns mapped twice. Please check these data columns: {{ duplicateDataColumns.join(', ') }}</p>
                <p v-if="!$v.payload.$invalid" class="green">
                    Mapping is valid.
                </p>
            </div>
            <div class="col col-3 align-right">
                <mercur-button
                    class="btn btn-yellow"
                    :disabled="isSavingProduct || !columnMapping.length || $v.payload.$invalid"
                    @click="save"
                    data-test="supplierImportProductProceed"
                >Save and continue</mercur-button>
            </div>
        </div>
        <mercur-dialog v-if="recommendedMapping" class="dialog-recommend" :is-open.sync="isRecommendedDialog">
            <p>Use recommended column mappings. If you already have some mapping, you can either replace it or append recommended items  to it. <strong>Note: </strong>you can always undo  these changes.</p>
            <div class="row">
                <div class="col col-6" v-for="(item, index) in recommendedMapping"  :key="index">
                    <ul class="list-recommend">
                        <li>Type: {{ item.type }}</li>
                        <li>Column Names: {{ item.columnNames.join(',') }}</li>
                        <li>Mapped To: {{ Object.keys(item.mappedTo).join(',') }}</li>
                    </ul>
                </div>
            </div>
            <div slot="footer">
                <mercur-button class="btn btn-yellow text-uppercase" @click="appendRecommended">Append Mappings</mercur-button>
                <mercur-button class="btn btn-yellow text-uppercase" @click="replaceWithRecommended">Replace Mappings</mercur-button>
            </div>
        </mercur-dialog>
    </mercur-card>
</template>
<script>
import FieldGroup from '../../../components/utils/FieldGroup'
import PrettySelect from '../../../components/utils/PrettySelect'
import SupplierImportedProductMixin, { validations } from './SupplierImportedProductMixin'

export default {
    name: 'SupplierImportedProductCsvFileColumnMapping',
    components: { PrettySelect, FieldGroup },
    mixins: [ SupplierImportedProductMixin ],
    props: {
        availableAttributeNames: {},
    },
    validations () {
        return {
            payload: {
                columnMapping: validations.columnMapping,
            },
        }
    },
    data () {
        return {
            mercurDataColumns: [
                'SUPPLIER_SKU',
                'SUPPLIER_REFERENCE',
                'SUPPLIER_PRODUCT_NAME',
                'COST',
                'QUANTITY',
                'PRODUCTION_DAY',
                'PACKS',
                'WEIGHT',
                'HS_CODE',
                'COORDINATES',
                'FACILITY',
            ],
            availableAttributes: null,
            columnSelection: [],
            columnMapping: [],
            recommendedMapping: null,
            isRecommendedDialog: false,
            undoValues: null,
        }
    },
    computed: {
        uploadRoute () {
            return {
                name: 'SupplierImportedProductUpload',
                params: {
                    ...this.$route.params,
                },
            }
        },
        payload () {
            return {
                columnMapping: this.columnMapping,
            }
        },
        sortedAttributeNames () {
            if (!this.availableAttributeNames || !this.supplierProductImportedDetails || !this.supplierProductImportedDetails.attributeConfiguration) {
                return null
            }

            const topAttributes = JSON.parse(JSON.stringify(this.supplierProductImportedDetails.attributeConfiguration))
            const topArray = []
            const bottomArray = []
            this.availableAttributeNames.forEach((item) => {
                if (Object.keys(topAttributes).includes(item)) {
                    topArray.push(item)
                } else {
                    bottomArray.push(item)
                }
            })

            return [...topArray.sort(), ...bottomArray.sort()]
        },
        missingAttributes  () {
            if (!this.supplierProductImportedDetails || !this.supplierProductImportedDetails.attributeConfiguration) {
                return []
            }
            const requiredAttributes = Object.keys(this.supplierProductImportedDetails.attributeConfiguration)
            const allMappedAttributes = this.columnMapping.flatMap(e => Object.keys(e.mappedTo))
            return requiredAttributes.filter((e) => !allMappedAttributes.includes(e))
        },
        missingData () {
            if (!this.supplierProductImportedDetails || !this.supplierProductImportedDetails.attributeConfiguration) {
                return []
            }

            const allMappedData = this.columnMapping.filter(mapSet => mapSet.type === 'DATA').flatMap(e => Object.keys(e.mappedTo))

            return [
                'COST',
                'QUANTITY',
                'PRODUCTION_DAY',
                'WEIGHT',
                'PACKS',
                'SUPPLIER_SKU',
                'SUPPLIER_REFERENCE',
                'HS_CODE',
            ].filter((e) => !allMappedData.includes(e))
        },
        duplicateAttributes () {
            let attributeNames = []
            this.payload.columnMapping.forEach(mapping => {
                if (mapping.type !== 'ATTRIBUTE') {
                    return
                }
                attributeNames.push(Object.keys(mapping.mappedTo))
            })
            attributeNames = attributeNames.flat().sort()

            const duplicateAttributeNames = []
            for (let i = 0; i < attributeNames.length - 1; i++) {
                if (attributeNames[i + 1] === attributeNames[i]) {
                    duplicateAttributeNames.push(attributeNames[i])
                }
            }
            return duplicateAttributeNames
        },
        duplicateAttributeColumns () {
            return this.duplicateColumnsByType('ATTRIBUTE')
        },
        duplicateDataColumns () {
            return this.duplicateColumnsByType('DATA')
        },
    },
    watch: {
        supplierProductImportedDetails: {
            handler (value) {
                if (value === null) {
                    return
                }

                if (this.supplierProductImportedDetails.columnMappingRecommended) {
                    const cloneRecommended = JSON.parse(JSON.stringify(this.supplierProductImportedDetails.columnMappingRecommended))
                    this.recommendedMapping = cloneRecommended.map(e => {
                        return {
                            ...e,
                            recommended: true,
                        }
                    })
                }

                if (this.supplierProductImportedDetails.columnMapping) {
                    this.columnMapping = JSON.parse(JSON.stringify(this.supplierProductImportedDetails.columnMapping))
                    this.initialValues = {
                        columnMapping: JSON.parse(JSON.stringify(this.supplierProductImportedDetails.columnMapping)),
                    }
                }

                this.$nextTick(() => {
                    if (this.supplierProductImportedDetails.productImportStatus !== 'PROCESSING_FILE') {
                        this.$emit('getSample')
                    }
                })
            },
            immediate: true,
        },
    },
    methods: {
        getNumberOfMappingsForColumn (columnName) {
            if (!this.columnMapping) {
                return
            }
            const count = this.columnMapping.filter(columnMappingSet => columnMappingSet.columnNames.includes(columnName)).length
            if (count) {
                return `(${count})`
            }
        },
        addColumnSelectionAs (type) {
            const columnNames = JSON.parse(JSON.stringify(this.columnSelection))
            this.columnMapping.push({
                type,
                columnNames,
                mappedTo: {},
            })
            this.$set(this, 'columnSelection', [])
        },
        save () {
            this.$emit('save', {
                ...this.payload,
                validateColumnMappings: true,
                onSuccess: () => {
                    this.skipCheckForChanges = true
                    this.$emit('pushRoute', { name: 'SupplierImportedProductCsvFileValueMapping' })
                },
            })
        },
        setColumnSetMappedToValue (mappedColumnSet, newConnections) {
            const mappedTo = JSON.parse(JSON.stringify(mappedColumnSet.mappedTo))
            const newMappedTo = Object.fromEntries(newConnections.map(connection => {
                return [
                    connection,
                    mappedTo[connection] || {
                        connectionName: connection,
                        type: 'VALUE',
                        value: null,
                        index: null,
                    },
                ]
            }))
            this.$set(mappedColumnSet, 'mappedTo', newMappedTo)
        },
        getColumnSetMappedToValue (mappedColumnSet) {
            return Object.keys(mappedColumnSet.mappedTo || [])
        },
        triggerRecommendedDialog () {
            this.isRecommendedDialog = true
        },
        appendRecommended () {
            if (!this.undoValues) {
                this.undoValues = JSON.parse(JSON.stringify(this.columnMapping))
            }

            this.$set(this, 'columnMapping', [
                ...this.columnMapping,
                ...this.recommendedMapping,
            ])
            this.isRecommendedDialog = false
        },
        replaceWithRecommended () {
            if (!this.undoValues) {
                this.undoValues = JSON.parse(JSON.stringify(this.columnMapping))
            }

            this.$set(this, 'columnMapping', this.recommendedMapping)
            this.isRecommendedDialog = false
        },
        undoMapping () {
            this.$set(this, 'columnMapping', this.undoValues)
        },
        isInvalidField (item) {
            return item.columnNames.every(e => !this.supplierProductImportedDetails.columnNames.includes(e))
        },
        isTopArray (item) {
            const topAttributes = JSON.parse(JSON.stringify(this.supplierProductImportedDetails.attributeConfiguration))
            if (Object.keys(topAttributes).includes(Object.values(item)[0])) {
                return true
            }
            return false
        },
        wasAlreadySelected (item) {
            if (!this.columnMapping.length) {
                return false
            }

            const allSelected = []
            this.columnMapping.forEach((item) => {
                allSelected.push(...Object.keys(item.mappedTo))
            })
            return allSelected.includes(Object.values(item)[0])
        },
        checkForChanges () {
            if (JSON.stringify(this.initialValues.columnMapping) !== JSON.stringify(this.columnMapping)) {
                return true
            }
            return false
        },
        duplicateColumnsByType (type) {
            const columnNames = this.payload.columnMapping
                .filter(mapSet => mapSet.type === type)
                .flatMap(e => e.columnNames.join(','))
                .sort()

            return columnNames.filter((name, index) => columnNames.indexOf(name) !== index)
        },
    },
    created  () {
        if (!this.isGettingImportedProductDetails) {
            this.$emit('get')
        }
    },
}
</script>

<style lang="scss" scoped>
    .no-wrap {
        flex-wrap: nowrap;
    }
    .column {
        margin-left: 5px;
        margin-right: 5px;
        min-width: 290px;
        transition: box-shadow 100ms cubic-bezier(0.64, 0.57, 0.67, 1.53), transform 400ms ease-out;
        &--attention {
            box-shadow: red 0 0 6px 0;
            transform: translateY(-4px);
        }
    }
    .column-selection {
        position: relative;
    }
    .add-button {
        position: absolute;
        top: -5px;
        right: -5px;
        z-index: 30;
    }
    .column-name {
        font-size: 12px;
    }
    .btn-recommend {
        float: right;
        vertical-align: middle;
    }
    .btn-undo {
        float: right;
        vertical-align: middle;
        margin-right: 15px !important;
    }
    .dialog-recommend {
        min-width: 50vw;
    }
    .list-recommend {
        list-style: none;
        border: 1px solid #e2e2e2;
        padding: 10px;
        margin-right: 5px;
        margin-bottom: 0;
        height: calc(100% - 14px);
    }
    .list-recommend:nth-child(even) {
        margin-left: 5px;
        margin-right: 0;
    }
    .green {
        color: #00bf25;
    }
</style>
